import React from "react";
import profile from "../img/profile.png";
import { BsArrowRight } from "react-icons/bs";

import icon1 from "../img/1.png";
import icon2 from "../img/2.png";
import icon3 from "../img/3.png";

import aboutimg from "../img/About.png";
import consultimg from "../img/Consult.png";

import comp1 from "../img/Company/1.png";
import comp2 from "../img/Company/2.png";
import comp3 from "../img/Company/3.png";
import comp4 from "../img/Company/4.png";
import SwiperPeople from "../Components/SwiperPeople";


export default function Home() {

    const Section1 = [
        {
            id:1,
            name: 'Leadership Training',
            img: icon1,
        },
        {
            id:2,
            name: 'Team Building',
            img: icon2,
        },
        {
            id:3,
            name: 'Keynote Speaking',
            img: icon3,
        }
    ];

    const Section5 = [
        {
            id:1,
            img: comp1
        },
        {
            id:2,
            img: comp2
        },
        {
            id:3,
            img: comp3
        },
        {
            id:4,
            img: comp4
        }
    ];


    return (
        <main className="relative overflow-hidden">
            
            <section className="w-screen h-screen lg:h-[750px] bg-[#1b2d5c] lg:polygon1 relative">
                <div className="lg:mx-auto mx-4 h-full max-w-screen-lg">
                    <div className="place-items-center h-full grid lg:grid-cols-2 lg:gap-8">
                        <div className=" flex flex-col space-y-8 text-white">
                            
                            <div  className="lg:hidden block h-full">
                                <img className=" w-full h-full" src={profile} />
                            </div>
                            
                            <div className="space-y-5">
                                <div>
                                    <h1 className="lg:text-xl text-yellow-500 font-medium uppercase">Leadership - Empowerment - Excellence</h1>
                                </div>

                                <div className=" lg:w-3/5">
                                    <span className=" uppercase lg:text-5xl !font-Roboto tracking-wide">Guidance with Vision</span>
                                </div>

                                <div>
                                    <span className=" font-medium text-md">
                                        Empowering leaders to achieve more.
                                    </span>
                                </div>

                            </div>
                            <div className="">
                                <button
                                    className="hover:shadow-lg font-txt-font flex items-center space-x-2 bg-main-color lg:bg-[#1b2d5c] text-white border-2 border-main-color hover:bg-main-color hover:-translate-y-2 hover:text-white ease-in-out delay-100 duration-150 md:text-lg w-[200px] text-md py-3 px-4 group cursor-pointer md:tracking-wide">
                                    <span className="">Learn More</span>
                                    <BsArrowRight />
                                </button>
                            </div>
                        </div>
                        <div  className="lg:w-[800px] lg:block hidden h-full">
                            <img className=" w-full h-full" src={profile} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="pt-6 pb-6 lg:pt-12 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 mx-auto max-w-screen-xl">
                        <div className="place-items-center h-full grid lg:grid-cols-3 gap-8 lg:gap-1">
                            {Section1.map((data) => {
                                switch(data.id) {
                                    case 1:
                                        return (
                                            <div key={data.id} className="flex flex-col cursor-pointer items-center space-y-8">
                                                <div className="w-[150px] shadow-lg border11 p-3 h-[150px]">
                                                    <img className="w-full border11 h-full" src={data.img} />
                                                </div>
                                                <span className=" font-Poppins text-xl ">{data.name}</span>
                                            </div>
                                        );
                                    
                                    case 2:
                                        return (
                                            <div key={data.id} className="flex relative cursor-pointer lg:top-20 flex-col items-center space-y-8">
                                                <div className="w-[150px] shadow-lg border11 p-3 h-[150px]">
                                                    <img className="w-full border11 h-full" src={data.img} />
                                                </div>
                                                <span className=" font-Poppins text-xl ">{data.name}</span>
                                            </div>
                                        );
                                    
                                    case 3:
                                        return (
                                            <div key={data.id} className="flex flex-col cursor-pointer items-center space-y-8">
                                                <div className="w-[150px] shadow-lg border11 p-3 h-[150px]">
                                                    <img className="w-full border11 h-full" src={data.img} />
                                                </div>
                                                <span className=" font-Poppins text-xl">{data.name}</span>
                                            </div>
                                        );

                                    default:
                                        return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bg-color">
                <div className="pt-6 pb-6 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 mx-4 lg:mx-auto max-w-screen-lg">
                        <div className="h-full grid place-items-center lg:grid-cols-2 lg:gap-1 gap-8">
                            <div className="relative w-[400px] p-5 h-[400px]">
                                <div className="absolute inset-0 pointer-events-none">
                                    <div className="yellow-dots absolute top-0 right-0"></div>
                                    <div className="yellow-dots absolute bottom-0 left-0"></div>
                                </div>
                                <img className="w-full h-full border12" src={aboutimg} />
                            </div>
                            <div className=" text-white flex flex-col mx-2 space-y-6 lg:space-y-8">
                                <div>
                                    <h1 className="font-bold text-4xl tracking-wider underline-offset-4 decoration-red-400 underline font-Poppins">Who We Are?</h1>
                                </div>
                                <div className=" flex text-md flex-col space-y-3">
                                    <span className="">
                                        At Benjamin Consulting Group, we bring over 30 years of military leadership experience to the business world. Our founder, a former US Army Infantry officer with commands ranging from platoon to brigade, has been nationally recognized for his ability to translate complex military strategies into actionable business solutions.
                                    </span>
                                    <span>
                                        Our mission is to empower organizations across industries with the tools and knowledge to lead effectively and build cohesive, high-performing teams. We believe that the principles of military leadership—discipline, resilience, and strategic thinking—are the key to driving success in today's competitive business environment.
                                    </span>
                                </div>
                                <div className="">
                                    <button
                                        className="hover:shadow-lg font-txt-font flex items-center space-x-2 bg-main-color lg:bg-[#1b2d5c] text-white border-2 border-main-color hover:bg-main-color hover:-translate-y-2 hover:text-white ease-in-out delay-100 duration-150 md:text-lg w-[200px] text-md py-3 px-4 group cursor-pointer md:tracking-wide">
                                        <span className="">Learn More</span>
                                        <BsArrowRight />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="">
                <div className="pt-6 pb-6 lg:pt-32 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 mx-4 lg:mx-auto max-w-screen-lg">
                        <div className="h-full grid lg:grid-cols-2 lg:gap-12 gap-8">
                            <div className="flex flex-col items-center justify-center">
                                <div className="flex flex-col space-y-6">
                                    <div>
                                        <span className=" text-2xl text-second-color font-medium">
                                            You seek strong leadership in your organization, but lack the expertise to cultivate it internally.
                                        </span>
                                    </div>
                                    <div className="">
                                        <button
                                            className="hover:shadow-lg font-txt-font flex items-center space-x-2 bg-main-color text-white border-2 border-main-color hover:bg-main-color hover:-translate-y-2 hover:text-white ease-in-out delay-100 duration-150 md:text-lg w-[200px] text-md py-3 px-4 group cursor-pointer md:tracking-wide">
                                            <span className="">Contact Us</span>
                                            <BsArrowRight />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className=" text-md">
                                    <span className="">
                                        On-site leadership training is essential—but not every organization has the in-house expertise. 
                                        That’s where We come in. We specialize in bridging the gaps in your 
                                        leadership development, helping your team grow into confident, 
                                        effective leaders who can drive your business forward.                                 
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bg-color">
                <div className="pt-6 pb-6 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 mx-4 lg:mx-auto max-w-screen-lg">
                        <div className="h-full grid place-items-center lg:grid-cols-2 lg:gap-1 gap-8">
                            
                        <div className=" text-white flex flex-col mx-2 space-y-6 lg:space-y-8">
                                
                                <div>
                                    <h1 className="font-bold text-4xl tracking-wider underline-offset-4 decoration-red-400 underline font-Poppins">Why Us?</h1>
                                </div>

                                <div className=" flex flex-col space-y-6">
                                    
                                    <ul className=" space-y-4">
                                        <li className=" flex flex-col space-y-1">
                                            <span className=" font-bold text-lg">1. Proven Leadership:</span>
                                            <span className=" text-gray-200">
                                                With a decorated career in the US Army, Robert Benjamin has led at the highest levels, managing diverse teams and complex operations with precision and effectiveness.
                                            </span>
                                        </li>
                                        <li className=" flex flex-col space-y-1">
                                            <span className=" font-bold text-lg">2. Tailored Solutions:</span>
                                            <span className=" text-gray-200">
                                                We understand that every organization is unique. That's why we offer customized training programs, workshops, and keynote presentations designed to meet your specific needs.                                            
                                            </span>
                                        </li>
                                        <li className=" flex flex-col space-y-1">
                                            <span className=" font-bold text-lg">3. National Recognition:</span>
                                            <span className=" text-gray-200">
                                                As a sought-after speaker and consultant, Robert Benjamin has been featured at major conferences and events, sharing insights that inspire and drive change.
                                            </span>
                                        </li>
                                    </ul>

                                    <div>
                                        <span className=" font-bold underline-offset-4 decoration-red-400 underline">
                                            I don’t just deliver speeches. I provide actionable training, so your team gains real leadership skills.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="relative w-[400px] p-5 h-[400px]">
                                <div className="absolute inset-0 pointer-events-none">
                                    <div className="yellow-dots absolute top-0 right-0"></div>
                                    <div className="yellow-dots absolute bottom-0 left-0"></div>
                                </div>
                                <img className="w-full h-full border12" src={consultimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="pt-6 pb-6 lg:pt-12 lg:pb-12">
                    <div className="lg:p-4 space-y-12 mx-auto max-w-screen-xl">
                        <div className=" flex items-center flex-col space-y-4 lg:space-y-8">
                            <div className=" w-4/5 text-center">
                                <span className=" lg:text-lg text-second-color">
                                    Organizations benefiting from ours leadership training
                                </span>
                            </div>
                            <div className=" grid lg:gap-8 grid-cols-2 lg:grid-cols-4">
                                {Section5.map((data) => (
                                    <div key={data.id} className="">
                                        <img src={data.img} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bg-color">
                <div className="pt-24 pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 mx-4 lg:mx-auto max-w-screen-xl">
                        <div className=" flex text-white flex-col space-y-12">
                            <div>
                                <h1 className="font-bold text-center text-4xl tracking-wider underline-offset-4 decoration-red-400 underline font-Poppins">Meet Our Team !</h1>
                            </div>

                            <div className="">
                                <SwiperPeople />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
