import {Swiper , SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import 'swiper/css';
import 'swiper/css/navigation';
import React , {useRef} from 'react';

import profile from "../img/profile.png";


export default function SwiperPeople() { 
    const ChangeUrl = (url) => {
        window.location.href = `/${url}`;
    };
    const swiperRef = useRef(null);

    const ServicesData = [
        {
            id:1,
            name: 'Robert Benjamin',
            role: 'CEO & President',
            img: profile
        },
        {
            id:2,
            name: 'Ann Spiratos',
            role: 'VP Business Development & Chief Marketing',
            img: profile
        },
        {
            id:3,
            name: 'Konstantinos Spiratos',
            role: 'CFO',
            img: profile
        },
        {
            id:4,
            name: 'Emily Spiratos',
            role: 'Administrative',
            img: profile
        }
    ]

    return (
        <div className=' relative'>
            <Swiper
            onSwiper={(swiper) => swiperRef.current = swiper}
                loop = {true}
                breakpoints={{
                    340: {
                        slidesPerView:1,
                    },
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    }
                }}
                autoplay = {{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // navigation = {true}
                modules={[Autoplay]}
            >
                {ServicesData.map((s) => {
                    return (
                        <SwiperSlide className='' key={s.id}>
                            <div className="lg:p-4">
                                <div onClick={() => ChangeUrl("Services")} className=" cursor-pointer border11 flex sticky lg:hidden">
                                    <img alt="#" className=" rounded-lg border11 h-full w-full" src={s.img} />
                                    <div className="absolute bg-black border11 bg-opacity-30 p-4 inset-0 z-10 flex flex-col justify-end opacity-100">
                                        <div className="ml-3 text-2xl text-red-200">
                                            <h1>{s.name}</h1>
                                        </div>
                                        <div className=" flex ml-4 text-lg w-4/5 text-white items-center space-x-1">
                                            <span className="">{s.role}</span>
                                        </div>
                                    </div>
                                </div>

                                <div onClick={() => ChangeUrl("Services")} className="sticky border11 cursor-pointer lg:block hidden">
                                    <img alt="#" className="rounded-lg border11 h-full w-full " src={s.img} />
                                    <div className=" absolute p-12 inset-0 z-10 bg-black border11 flex flex-col justify-end opacity-0 hover:opacity-100 group ease-linear duration-500 transition-all bg-opacity-50">
                                        <div className="ml-3 text-2xl">
                                            <h1 className=' group-hover:text-main-color'>{s.name}</h1>
                                        </div>
                                        <div className=" flex ml-4 text-lg items-center space-x-1">
                                            <span className="group-hover:text-white">{s.role}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )  
};