import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import Footer from './Components/Footer';


function App() {
	return (
		<main className=' font-Poppins'>
			<Navbar />
			<Home />
			<Footer />
		</main>
	);
}

export default App;
